import { useState } from 'react';

export const useHandleScroll = () => {
  const [selecteditem, setselecteditem] = useState(0);

  const handleScroll = (event: any) => {
    const height = window.innerHeight;
    const heightOffset = height;
    if (window.pageYOffset === 0) {
      setselecteditem(0);
      document.body.style.backgroundColor = '#003352';
      document.body.style.transition = 'background-color 0.5s ease-in-out';
      return; // Sale de la función si no se ha realizado desplazamiento
    }
    /*
    if (
      document.getElementById('vision')!.getBoundingClientRect().top <
      heightOffset
    ) {
      setselecteditem(0);
      document.body.style.backgroundColor = '#003352';
      if (window.innerWidth < 900) {
        document.body.style.transition = 'background-color 0.2s ease-in-out';
      } else {
        document.body.style.transition = 'background-color 0.5s ease-in-out';
      }
    }
    
    if (document.getElementById("mission")!.getBoundingClientRect().top < heightOffset) {
      setselecteditem(1);
      document.body.style.backgroundColor = "#003352";
      if (window.innerWidth < 900) {
        document.body.style.transition = "background-color 0.2s ease-in-out";
      } else {
        document.body.style.transition = "background-color 0.5s ease-in-out";
      }
    }

    if (
      document.getElementById('services')!.getBoundingClientRect().top <
      heightOffset
    ) {
      setselecteditem(2);
      document.body.style.backgroundColor = '#FCFCFF';
      if (window.innerWidth < 900) {
        document.body.style.transition = 'background-color 0.2s ease-in-out';
      } else {
        document.body.style.transition = 'background-color 0.5s ease-in-out';
      }
    }
    if (
      document.getElementById('goal')!.getBoundingClientRect().top <
      heightOffset
    ) {
      setselecteditem(3);
      document.body.style.backgroundColor = '#FCFCFF';
      if (window.innerWidth < 900) {
        document.body.style.transition = 'background-color 0.2s ease-in-out';
      } else {
        document.body.style.transition = 'background-color 0.5s ease-in-out';
      }
    }
    if (
      document.getElementById('freetrial')!.getBoundingClientRect().top <
      heightOffset
    ) {
      setselecteditem(5);
    }

    if (
      document.getElementById('benefits')!.getBoundingClientRect().top <
      heightOffset
    ) {
      setselecteditem(6);
      document.body.style.backgroundColor = '#003352';
      if (window.innerWidth < 900) {
        document.body.style.transition = 'background-color 0.2s ease-in-out';
      } else {
        document.body.style.transition = 'background-color 0.7s ease-in-out';
      }
    }
    if (
      document.getElementById('functioning')!.getBoundingClientRect().top <
      heightOffset
    ) {
      setselecteditem(7);
      document.body.style.backgroundColor = '#003352';
      if (window.innerWidth < 900) {
        document.body.style.transition = 'background-color 0.2s ease-in-out';
      } else {
        document.body.style.transition = 'background-color 0.5s ease-in-out';
      }
    }*/
  };

  return { selecteditem, handleScroll };
};
